<template>
    <div class="productionDeliveryBoxEcharts">
        <div class="productionDeliveryMonthEcharts">
            <el-date-picker
                v-model="value1"
                type="monthrange"
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份"
                format="yyyy-MM"
                value-format="yyyy-MM"
                @change="changePicker"
            >
            </el-date-picker>
            <div class="top-echarts-two">
                <div class="echarts-left echarts-total">
                    <div class="title-box">
                        <h1>发货方量</h1>
                        <span @click="lookMore(1)">查看完整数据</span>
                    </div>
                    <div style="width 100%;height:300px;flex 4" id="contractQuantityId"></div>
                </div>
                <div class="echarts-right echarts-total">
                    <div class="title-box">
                        <h1>签收方量</h1>
                        <span @click="lookMore(2)">查看完整数据</span>
                    </div>
                    <div style="width 100%;height:300px;flex 4" id="estimatedSalesId"></div>
                </div>
            </div>
            <div class="table-list">
                <el-table
                    :data="tabListDataName"
                    style="width: 100%"
                    fixed
                    max-height="300"
                    show-summary
                    :summary-method="getSummaries"
                >
                    <el-table-column
                        prop="name"
                        label="公司名称"
                        width="150"
                        fixed
                    >
                    </el-table-column>
                    <el-table-column
                        v-for="(date, index) in tabListDataDate"
                        :key="index"
                        :label="date"
                        width="113"
                    >
                        <el-table-column
                            label="发货方量(方)"
                            width="113"
                            :prop="date"
                        >
                            <template slot-scope="scope">
                                <span v-for="(key, idx) in scope.row.list" :key="idx">
                                    <template v-if="key.date === date">
                                        {{ key.fhfl }}
                                    </template>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="签收方量(方)"
                            width="113"
                            :prop="date"
                        >
                            <template slot-scope="scope">
                                <span v-for="(key, idx) in scope.row.list" :key="idx">
                                    <template v-if="key.date === date">
                                        {{ key.qsfl }}
                                    </template>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="签收率"
                            width="113"
                            :prop="date"
                        >
                            <template slot-scope="scope">
                                <span v-for="(key, idx) in scope.row.list" :key="idx">
                                    <template v-if="key.date === date">
                                        {{ key.qsl }}
                                    </template>
                                </span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column
                        prop="ljfhfl"
                        label="累积发货方量(方)"
                        fixed="right"
                        width="150"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="ljqsfl"
                        label="累积签收方量(方)"
                        fixed="right"
                        width="150"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="ljqsl"
                        label="累积签收率"
                        fixed="right"
                        width="150"
                    >
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <alertEcharts v-if="showContract" :alert-status="alertStatus" @closeChildAlert="closeChildFn"></alertEcharts>
        <byLabelChild v-if="showByLabelChild" @closeChildAlert="closeChildFn"></byLabelChild>
    </div>
</template>

<script>
// import addBgImages from './utils';
import alertEcharts from './components/productionDelivery/productionDeliveryMore';
import byLabelChild from './components/productionDelivery/productionDeliveryChild';
export default {
    data() {
        return {
            showContract: false,
            showByLabelChild: false,
            alertStatus: 0,
            tabListData: [],
            tabListDataName: [],
            tabListDataList: [],
            tabListDataDate: [],
            tabListEchartsHt: [],
            echartsColor: ['#EA8343', '#3E79C4', '#E6E429', '#FA9E94'],
            value1: [],
        };
    },
    components: {
        alertEcharts,
        byLabelChild,
    },
    mounted() {
        this.$nextTick(() => {
            const dateTime = this.switchTime() + '-01-01';
            this.value1 = [dateTime, this.switchTime('all')];
            // 生产发货统计
            this.salescontractMonths();
        });
    },
    methods: {
        changePicker() {
            this.salescontractMonths();
        },
        switchTime(isAall) {
            const date = new Date();
            const y = date.getFullYear(); // 获取完整的年份(4位,1970)
            let m = date.getMonth() + 1; // 获取月份(0-11,0代表1月,用的时候记得加上1)
            let d = date.getDate(); // 获取日(1-31)
            m = m > 9 ? m : '0' + m;
            d = d > 9 ? d : '0' + d;
            if (isAall === 'all') {
                return y + '-' + m;
            }
            return y;
        },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                // 获取与当前行时间相同的数据
                const arr = this.tabListDataList.filter(item => {
                    return column.property === item.date;
                });
                // 对应时间合同数量和预计发货方量(方)的数值数组 // 对应每一列的数据数组
                let values = arr.map(item => {
                    if (column.label === '发货方量(方)') {return item.fhfl;}
                    if (column.label === '签收方量(方)') {return item.qsfl;}
                });
                if (column.label === '累积发货方量(方)') {
                    values = data.map(item => {
                        if (column.label === '累积发货方量(方)') {
                            return item.ljfhfl;
                        }
                    });
                }
                if (column.label === '累积签收方量(方)') {
                    values = data.map(item => {
                        if (column.label === '累积签收方量(方)') {
                            return item.ljqsfl;
                        }
                    });
                }
                // const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        }
                        return prev;
                    }, 0);
                } else {
                    sums[index] = ' ';
                    if (column.label === '签收率') {
                        sums[index] = !Number(sums[index - 2]) ? ' ' : (sums[index - 1] / sums[index - 2] * 100).toFixed(2) + '%';
                    } else if (column.label === '累积签收率') {
                        // 计算合计签收率
                        sums[index] = !Number(sums[index - 2]) ? ' ' : (sums[index - 1] / sums[index - 2] * 100).toFixed(2) + '%';
                    }
                }
            });
            return sums;
        },
        closeChildFn() {
            this.showByLabelChild = false;
            this.showContract = false;
        },
        // 查看详情
        lookMore(status) {
            // status 7:发货方量  8:签收方量
            this.alertStatus = status;
            this.showContract = true;
        },
        // 发货方量
        contractQuantityCh(data) {
            const myChart = this.$echarts.init(document.getElementById('contractQuantityId'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                legend: {
                    data: this.tabListEchartsHt,
                    padding: [10, 0, 0, 0],
                },
                grid: {
                    top: '20%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.tabListDataDate,
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '单位(方)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                            padding: [10, 0, 0, 0],
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                fontSize: 10,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                    {
                        type: 'value',
                        name: '单位(方)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                series: data,
            };
            const that = this;
            myChart.on('click', function () {
                that.showByLabelChild = true;
            });
            myChart.setOption(option, true);
        },
        // 签收方量
        estimatedSalesCh(data) {
            const myChart = this.$echarts.init(document.getElementById('estimatedSalesId'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                legend: {
                    data: this.tabListEchartsHt,
                    padding: [10, 0, 0, 0],
                },
                grid: {
                    top: '20%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.tabListDataDate,
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '单位(方)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                    {
                        type: 'value',
                        name: '单位(方)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                series: data,
            };
            const that = this;
            myChart.on('click', function () {
                that.showByLabelChild = true;
            });
            myChart.setOption(option, true);
        },
        // 生产发货统计
        salescontractMonths() {
            this.$axios
                .get(`/interfaceApi/report/produceanalysis/scfh/cz/months?startDate=${this.value1[0]}&endDate=${this.value1[1]}`)
                .then(res => {
                    this.tabListData = res;
                    this.tabListDataList = res.list;
                    this.tabListDataDate = res.date;
                    // 表格渲染
                    this.tabListDataName = res.name.map(item => {
                        let ljfhfl = 0;
                        let ljqsfl = 0;
                        let ljqsl = 0;
                        const arr = res.list.filter(key => {
                            return key.name === item;
                        });
                        arr.forEach(item => {
                            ljfhfl += Math.round(item.fhfl * 100) / 100;
                            ljqsfl += Math.round(item.qsfl * 100) / 100;
                            ljqsl = ljqsfl / ljfhfl;
                        });
                        return {
                            name: item,
                            list: arr,
                            ljfhfl: ljfhfl,
                            ljqsfl: ljqsfl,
                            ljqsl: isNaN(ljqsl) ? 0 + '%' : (ljqsl * 100).toFixed(2) + '%',
                        };
                    });
                    // 处理图表数据
                    this.tabListEchartsHt = res.name;
                    this.tabListEchartsHt.push('合计');
                    // 分组名称
                    const getSeriesData = res.name.map(item => {
                        const arr = res.list.filter(key => {
                            return key.name === item;
                        });
                        return arr;
                    });
                    // 发货方量
                    let htEchartSeriesData = [];
                    htEchartSeriesData = getSeriesData.map((item, index) => {
                        let htItemListData = [];
                        htItemListData = item.map(info => {
                            return info.fhfl;
                        });
                        const arrName = this.tabListEchartsHt[index];
                        const arrColor = this.echartsColor[index];
                        const htEchartSeries = {
                            name: arrName,
                            type: 'bar',
                            data: htItemListData,
                            label: {
                                fontFamily: 'QuartzLTStd',
                                show: true,
                                position: 'top',
                                color: arrColor,
                                fontSize: '14',
                                padding: 5,
                                // backgroundColor: {
                                //     image: addBgImages(index, 'top'),
                                // },
                            },
                            itemStyle: {
                                color: arrColor,
                            },
                        };
                        return htEchartSeries;
                    });
                    // 签收方量
                    let flEchartSeriesData = [];
                    flEchartSeriesData = getSeriesData.map((item, index) => {
                        let flItemListData = [];
                        flItemListData = item.map(info => {
                            return info.qsfl;
                        });
                        const arrName = this.tabListEchartsHt[index];
                        const arrColor = this.echartsColor[index];
                        const flEchartSeries = {
                            name: arrName,
                            type: 'bar',
                            data: flItemListData,
                            label: {
                                fontFamily: 'QuartzLTStd',
                                show: true,
                                position: 'top',
                                color: arrColor,
                                fontSize: '14',
                                padding: 5,
                                // backgroundColor: {
                                //     image: addBgImages(index, 'top'),
                                // },
                            },
                            itemStyle: {
                                color: arrColor,
                            },
                        };
                        return flEchartSeries;
                    });
                    // 发货方量合计
                    const htTotalData = res.date.map(item => {
                        const arr = res.list.filter(key => {
                            return key.date === item;
                        });
                        let total = 0;
                        arr.forEach(a => {
                            total = Math.round(total * 100 + a.fhfl * 100) / 100;
                        });
                        return total;
                    });
                    const htTotal = {
                        name: '合计',
                        type: 'line',
                        yAxisIndex: 1,
                        data: htTotalData,
                        label: {
                            fontFamily: 'QuartzLTStd',
                            show: true,
                            position: 'bottom',
                            fontSize: '14',
                            padding: 5,
                            // backgroundColor: {
                            //     image: addBgImages(2, 'top'),
                            // },
                        },
                        itemStyle: {
                            normal: {
                                color: '#73DABE',
                            },
                        },
                    };
                    // 签收方量合计
                    const flTotalData = res.date.map(item => {
                        const arr = res.list.filter(key => {
                            return key.date === item;
                        });
                        let total = 0;
                        arr.forEach(a => {
                            total = Math.round(total * 100 + a.qsfl * 100) / 100;
                        });
                        return total;
                    });
                    const flTotal = {
                        name: '合计',
                        type: 'line',
                        yAxisIndex: 1,
                        data: flTotalData,
                        label: {
                            fontFamily: 'QuartzLTStd',
                            show: true,
                            position: 'bottom',
                            fontSize: '14',
                            padding: 5,
                            // backgroundColor: {
                            //     image: addBgImages(2, 'top'),
                            // },
                        },
                        itemStyle: {
                            normal: {
                                color: '#73DABE',
                            },
                        },
                    };
                    const htCompleteData = htEchartSeriesData.slice(0, -1);
                    htCompleteData.push(htTotal);
                    const flCompleteData = flEchartSeriesData.slice(0, -1);
                    flCompleteData.push(flTotal);
                    // 发货方量图表
                    this.contractQuantityCh(htCompleteData);
                    // 签收方量图表
                    this.estimatedSalesCh(flCompleteData);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.message);
                });
        },
    },
};

</script>
<style lang='stylus'>
.productionDeliveryBoxEcharts
    height 100%
.productionDeliveryMonthEcharts
    height 100%
    display flex
    padding .2rem
    padding-top .1rem
    display flex
    flex-direction column
    background #fff
    overflow-y auto
    .el-table__fixed-right thead tr th
        &:last-child
            background #F5F2ED !important
            color #823802 !important
        &:nth-last-child(2)
            background #F5F2ED !important
            color #823802 !important
         &:nth-last-child(3)
            background #F5F2ED !important
            color #823802 !important
    .el-table__fixed-footer-wrapper table tbody tr
        td
            background none
            color #C9661E
            &:first-child
                background #F5F2ED
                color #823802
                font-size .16rem
    .el-table__footer-wrapper table tbody td
        background none
    .top-echarts-two
        display flex
        width 100%
        height 50%
        margin-top .1rem
        .echarts-total
            flex 1
            // width 100%
            // height 50%
            padding .2rem
            border 1px solid #D7D7D7
            display flex
            flex-direction column
            .title-box
                display flex
                justify-content space-between
                flex 1
                h1
                    color #333333
                    font-size .18rem
                    &:before
                        content '`'
                        width .06rem
                        height .2rem
                        background #4D7DE0
                        color #4D7DE0
                        margin-right .1rem
                span
                    color #1577D2
                    height .25rem
                    padding 0 .2rem;
                    font-size .14rem
                    cursor pointer
                    border 1px solid #1577D2
        .echarts-left
            margin-right .2rem
    .table-list
        margin-top .2rem
        width 100%
        height 50%
        padding-bottom .2rem
        .el-table
            .el-table__header
                border-collapse: collapse;
            thead tr th
                color #022782
                font-size .16rem
                text-align center
                padding 0
            tbody tr td
                height .4rem
                text-align center
                padding 0
                font-size .14rem
</style>